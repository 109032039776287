@import "abstracts/_abstracts";
.ListProductsHp {
    padding: 0 0 4rem;

    &-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        @include media('>xs') {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media('>md') {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-title {
        font-size: clamp(1.5rem, 3vw, 2rem);
        line-height: 1.25;
    }
}